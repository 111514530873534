<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <SalesManaPublishInfoPopup v-if="docs.isShow" @close="docs.isShow=false"></SalesManaPublishInfoPopup>
        <CarrotTitle title="세금계산서 발행 등록 관리">
            <div>
                <button @click="docs.isShow=true" class="btn-info ml-225">info</button>
                <div class="sales-text">
                    ※ 일반발행은 당일 or 익일 발행 (16시 이후 올릴 시 익일 처리)<br/>
                    ※ 긴급발행은 당일 발행 (16시 이후 올릴 시 익일 처리)<br/>
                    ※ 모든 계산서는 팀장승인 후 발행 합니다.
                </div>
            </div>
            <div class="btn-ibbox title-btnbox float-right">
                <router-link to="/documents/TotalSalesAmountList"><button class="btn-default">전체 매출 금액 보기</button></router-link>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">기간</th>
                                <td>
                                    <CarrotDatePicker class="inline-datepicker" v-model="search.info.sdate"></CarrotDatePicker> ~
                                    <CarrotDatePicker class="inline-datepicker" v-model="search.info.edate"></CarrotDatePicker>
                                </td>
                                <th width="180">매출형태</th>
                                <td>
                                    <select v-model="search.info.stype" class="w-70per">
                                        <option value="">선택</option>
                                        <option value="계산서">계산서</option>
                                        <option value="세금계산서">세금계산서</option>
                                        <option value="카드결제">카드결제</option>
                                        <option value="개인입금">개인입금</option>
                                        <option value="RF">RF</option>
                                        <option value="수출">수출</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th width="180">부문</th>
                                <td>
                                    <CarrotSector v-model="search.info.idx_sector"></CarrotSector>
                                    <CarrotDept :idx_sector = "search.info.idx_sector" v-model="search.info.idx_office"></CarrotDept>
                                </td>
                                <th width="180">팀</th>
                                <td>
                                    <CarrotTeam :idx_sector = "search.info.idx_sector" :idx_office = "search.info.idx_office" v-model="search.info.idx_team" ></CarrotTeam>
                                </td>
                            </tr>
                            <tr>
                                <th width="180">HQ매니저</th>
                                <td>
                                    <CarrotStaff :idx_sector= "search.info.idx_sector" :idx_team = "search.info.idx_team" v-model="search.info.idx_staff"></CarrotStaff>
                                </td>
                                <th width="180">회사명</th>
                                <td>
                                    <CarrotCompany v-model="search.info.idx_company" class="w-100per"></CarrotCompany>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-right mt-10" @click="docs.getSales()">검색</button>
                    <div class="clear"></div>

                    <table class="table-col mt-50 w-100per" v-if="docs.report!=null">
                        <colgroup>
                            <col span="5">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>내가 올린 매출</th>
                                <th>확인 요청</th>
                                <th>발행요청</th>
                                <th>팀장승인</th>
                                <th>발행완료</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td @click="docs.getSalesByState('내가올린매출')" style="cursor:pointer">{{docs.report.mysales}} 건</td>
                                <td @click="docs.getSalesByState('확인요청')" style="cursor:pointer">{{docs.report.cnt_check}} 건</td>
                                <td @click="docs.getSalesByState('발행요청')" style="cursor:pointer">{{docs.report.cnt_request}} 건</td>
                                <td @click="docs.getSalesByState('팀장승인')" style="cursor:pointer">{{docs.report.cnt_sign}} 건</td>
                                <td @click="docs.getSalesByState('발행완료')" style="cursor:pointer">{{docs.report.cnt_finished}} 건</td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <div v-if="docs.report!=null">
                        <div class="block-title mt-50 mb-20">승인 완료 매출</div>
                        <table class="table-col">
                            <colgroup>
                                <col span="5">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>총 금액</th>
                                    <th>글로벌사업본부</th>
                                    <th>스마트러닝사업본부</th>
                                    <th>마이크로러닝</th>
                                    <th>기타<br>(경영, 밝음, 연구소, GP)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{docs.report.price_total}}</td>
                                    <td>{{docs.report.sum_office27}}</td>
                                    <td>{{docs.report.sum_office22}}</td>
                                    <td>{{docs.report.sum_office937}}</td>
                                    <td>{{docs.report.sum_office_etc}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->

                    <div class="mt-50 mb-20 dp-table w-100per">
                        <select class="w-200px" v-model="search.info.state" @change="docs.getSales()">
                            <option value="">발행 전체</option>
                            <option value="정발행">정발행</option>
                            <option value="선발행">선발행</option>
                            <option value="역발행">역발행</option>
                        </select>
                        <select class="w-200px ml-20" v-model="search.info.corp" @change="docs.getSales()">
                            <option value="">구분 전체</option>
                            <option value="캐럿글로벌">캐럿글로벌</option>
                            <option value="캐럿에듀">캐럿에듀</option>
                            <option value="캐럿에듀플러스">캐럿에듀플러스</option>
                            <option value="캐럿솔루션즈">캐럿솔루션즈</option>
                            <option value="밝음">밝음</option>
                            <option value="모리와함께">모리와함께</option>
                        </select>
                        <select class="w-200px ml-20" v-model="search.info.rf" @change="docs.getSales()">
                            <option value="">일반+RF</option>
                            <option value="일반">일반</option>
                            <option value="RF">RF</option>
                        </select>
                        <select class="w-200px ml-20" v-model="search.info.sign_step" @change="docs.getSales()">
                            <option value="">상태 전체</option>
                            <option value="발행요청">발행요청</option>
                            <option value="팀장승인">팀장승인</option>
                            <option value="발행완료">발행완료</option>
                        </select>
                        <router-link :to="{ name: 'documents-SalesManagementPublishAdd' }"><button class="btn-default float-right ml-20">매출등록</button></router-link>
                        <button @click="docs.getExcel()" class="btn-default float-right">엑셀다운로드</button>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="30">
                            <col width="60">
                            <col width="70">
                            <col width="100">
                            <col width="110">
                            <col width="100">
                            <col width="90">
                            <col width="130">
                            <col width="110">
                            <col width="200">
                            <col width="100">
                            <col width="80">
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="pd-0">
                                    <label><input type="checkbox" v-model="docs.checkAll" @change="docs.setCheckAll()"></label>
                                </th>
                                <th>No</th>
                                <th>발행</th>
                                <th>팀</th>
                                <th>매니저</th>
                                <th>구분</th>
                                <th>매출형태</th>
                                <th>매출발생일</th>
                                <th>회사명</th>
                                <th>내역</th>
                                <th>금액</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="docs.list.length==0">
                                <td colspan="13">데이터가 없습니다.</td>
                            </tr>
                            <tr v-for="(itm,idx) in docs.list" :key="idx" :class="{'bg-c1':itm.is_check=='Y'}">
                                <td class="pd-0">
                                    <label><input type="checkbox" name="idx_doc_sales" v-model="docs.selected" :value="itm.idx"></label>
                                </td>
                                <td>{{itm.num}}</td>
                                <td>{{itm.state}}</td>
                                <td class="txt-ellipsis">{{itm.dept}}</td>
                                <td class="txt-ellipsis">{{itm.staff_ename}} ({{itm.staff_kname}})</td>
                                <td>{{itm.corp}}</td>
                                <td>{{itm.stype}}</td>
                                <td>
                                  <p v-if="itm.emergency=='Y' && itm.sign_step!='발행완료'" style="color:red">
                                    {{itm.cdate}}(긴급)
                                  </p>
                                  <p v-else >{{itm.cdate}}

                                  </p>
                                </td>
                                <td class="txt-ellipsis">{{itm.company_name}}</td>
                                <td>
                                                                        <router-link target="_blank" :to="'/documents/SalesManagementPublishView/' + itm.idx" ><span class="btn-view"><span v-if="itm.detail!=''" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 150px; display: inline-block; vertical-align: top;">{{itm.detail}}</span><span v-else> 작성된 내역 없음 </span><span v-if="itm.is_rf=='Y'">[RF]</span><span v-if="itm.cnt_comment>0" class="txt-orange">({{itm.cnt_comment}})</span></span></router-link>
                                </td>
                                <td>{{itm.price}}</td>
                                <td>
                                    <span v-if="itm.sign_step=='발행요청' && store.state.position=='매니저'" style="color:#FF0000">발행요청</span>
                                    <span v-if="itm.sign_step=='팀장승인' && !store.state.isSalesAdmin " style="color:#0100FF" >팀장승인</span>
                                    <span class="btn-default type3" @click="docs.setStep(itm, '팀장승인')" v-if="itm.sign_step=='발행요청' && itm.idx_sign_allow == store.state.idx_staff" style="color:#00bb64; background: #e3fff2;" >팀장승인</span>
                                    <span v-if="itm.sign_step=='발행요청' && store.state.position=='리드' && itm.idx_sign_allow !== store.state.idx_staff " style="color:#0100FF" >팀장승인</span>
                                    <span class="btn-default type3" @click="docs.setStep(itm, '발행완료')" v-if="itm.sign_step=='팀장승인' && store.state.isSalesAdmin " style="color:#FF339A; background: #FFEDF4;" >발행승인</span>
                                    <span v-if="itm.sign_step=='발행완료'" style="color:#000000" >발행완료</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging v-if="docs.total>0" :total="docs.total" :list_per_page="50" v-model="search.info.page" @change="docs.getSales()"></CarrotPaging>
                    <button @click="docs.setSelectedStep('세금계산서승인')" v-if="store.state.isSalesAdmin " class="btn-default float-right mt-30 ml-20">승인완료</button>
                    <button @click="docs.setSelectedStep('발행완료')" v-if="store.state.isSalesAdmin " class="btn-default float-right mt-30">발행완료</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotSector from '@/components/common/CarrotSector.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import SalesManaPublishInfoPopup from '@/components/popup/payment/SalesManaPublishInfoPopup.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue';
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification';
import {useRoute} from "vue-router";

export default {
    layout:"documents",
    components: {
        CarrotDatePicker, CarrotSector, CarrotDept, CarrotTeam, CarrotStaff, SalesManaPublishInfoPopup, CarrotCompany
    },
    setup() {
        const toast = useToast()
        const store = useStore()
        const route = useRoute();
        const search = reactive({
            info : {
                page : 1,
                sdate: "",
                edate: "",
                stype : "", // 매출 형태
                idx_sector : 0,
                idx_office : 0,
                idx_team : 0,
                idx_staff : 0,
                idx_company : 0,
                state : "",
                corp : "",
                rf : "",
                sign_step : "",

            },
            leftPad : (value) => {
                if (value >= 10) { 
                    return value; 
                } 
                return `0${value}`;
            },
            toStringByFormatting : (source, delimiter = '-') => {
                 const year = source.getFullYear();
                 const month = search.leftPad(source.getMonth() + 1);
                 const day = search.leftPad(source.getDate());
                 return [year, month, day].join(delimiter); 
            }

        });

        const docs = reactive({
            isInit : false,
            isShow : false,
            list : [],
            selected : [],
            report : null,
            total : 0,
            checkAll : false,
            setCheckAll : () => {
                if(docs.checkAll) {
                    docs.selected = [];
                    for(let i=0;i<docs.list.length;i++) {
                        if(docs.selected.indexOf(docs.list[i].idx)<0) {
                            docs.selected.push(docs.list[i].idx);
                        }
                    }
                } else {
                    docs.selected = [];
                }
            },
            setStep : (itm, val) => {
                let params = {
                    idx : itm.idx,
                    value : val
                };
                axios.post('/rest/sales/setStep', params).then((res) => {
                    if( res.data.err == 0 ){
                        if(val=="발행완료" || val=="팀장승인") {
                            for(let i=0;i<docs.list.length;i++) {
                                if(docs.list[i].idx == itm.idx) {
                                    docs.list[i].sign_step = val;
                                }
                            }
                        }
                        if(val=="세금계산서승인") {
                            itm.sign_confirm = 'Y';
                            for(let i=0;i<docs.list.length;i++) {
                                if(docs.list[i].idx == itm.idx) {
                                    docs.list[i].sign_confirm = itm.sign_confirm;
                                }
                            }
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            setSelectedStep : (val) => {
                let params = {
                    idxs : docs.selected,
                    value : val
                };
                axios.post('/rest/sales/setSelectedStep', params).then((res) => {
                    if( res.data.err == 0 ){
                        docs.getSales();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            getExcel : () => {
                let params = search.info;
                let str = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
                location.href="/rest/sales/getSalesExcelList?" + str;
            },
            getSales: () => {
                if(docs.isInit==false) {
                    return;
                }
                let params = search.info;
                
                axios.get('/rest/sales/getSalesList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        docs.list = res.data.list;
                        docs.report = res.data.report;
                        docs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            getSalesByState: (val) => {
              if(docs.isInit==false) {
                return;
              }
              if(val=="내가올린매출")
                 search.info.idx_staff=store.state.idx_staff;
              else{
                search.info.idx_staff=store.state.idx_staff;
                search.info.sign_step=val;
              }
              let params = search.info;

              axios.get('/rest/sales/getSalesList', { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  docs.list = res.data.list;
                  docs.report = res.data.report;
                  docs.total = res.data.total;
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },


        });
        
        onMounted(() => {
            // Mounted


            let today = new Date();
            search.info.idx_sector = store.state.idx_sector;
            search.info.idx_team = store.state.idx_team;

            //통계리스트 연동
            if(route.params.idx_office)
              search.info.idx_office=route.params.idx_office;
            if(route.params.idx_sector)
              search.info.idx_sector=route.params.idx_sector;
            if(route.params.corp)
              search.info.corp=route.params.corp;
            if(route.params.sign_step)
              search.info.sign_step=route.params.sign_step;

            search.info.sdate = search.toStringByFormatting(new Date(today.getFullYear(), today.getMonth(), 1));
            search.info.edate = search.toStringByFormatting(new Date(today.getFullYear(), today.getMonth()+1, 0));
            docs.isInit = true;
            docs.getSales();


        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {search, docs, store};
    }
}
</script>
<style lang="scss" scoped>
.sales-text{
    position: absolute;
    top: 4px;
    margin-left : 250px;
    color: rgb(255, 53, 53);
}
</style>