<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div>
                <p class="title">세금계산서 발행 등록 관리 안내</p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="txt-left pb-10">
                    - 전월 매출은 10일까지 등록 가능합니다.<br>
                    - 액션에 따른 상태 및 수정가능 여부
                </div>
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="120">
                        <col width="160">
                        <col width="160">
                        <col width="160">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>액션</th>
                            <td class="txt-center bg-c1 txt-black">매출등록</td>
                            <td class="txt-center bg-c1 txt-black">리드승인 클릭<br>(리드)</td>
                            <td class="txt-center bg-c1 txt-black">회계담당자<br>발행완료</td>
                        </tr>
                        <tr>
                            <th class="h-50px">상태</th>
                            <td class="txt-center bg-c2 txt-black">발행요청</td>
                            <td class="txt-center bg-c2 txt-black">리드승인</td>
                            <td class="txt-center bg-c2 txt-black">발행완료</td>
                        </tr>
                        <tr>
                            <th>수정가능여부</th>
                            <td class="txt-center bg-c3 txt-black">수정가능</td>
                            <td class="txt-center bg-c3 txt-black">수정가능<br>(리드승인 재필요)</td>
                            <td class="txt-center bg-c3 txt-black">수정불가</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'SalesManaPublishInfoPopup',
    emits: [ 'close' ],
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>